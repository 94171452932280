<script setup lang="ts">
import { useSlots } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { useDisplay } from 'vuetify'
import { randomIntFromInterval } from 'dvn-js-utils'

const props = withDefaults(
  defineProps<{
    random?: boolean
    increment?: boolean
  }>(),
  {
    random: true,
    increment: false,
  },
)

const slots = useSlots()

const videos = ['login-11.mp4', 'login-13.mp4']

const videoIndex = ref(0)
const LOCALSTORAGE_KEY = 'login-video-index'

function getVideoIndex() {
  const index = Number(useLocalStorage(LOCALSTORAGE_KEY, 0).value)
  if (index < videos.length - 1) {
    const inc = index + 1
    useLocalStorage(LOCALSTORAGE_KEY, 0).value = inc
    return inc
  }
  useLocalStorage(LOCALSTORAGE_KEY, 0).value = 0
  return 0
}

const video = computed(() => {
  if (props.random === true) return `/videos/${videos[randomIntFromInterval(0, videos.length - 1)]}`
  if (props.increment === true) return `/videos/${videos[videoIndex.value]}`
  return `/videos/${videos[0]}`
})

onBeforeMount(() => {
  videoIndex.value = getVideoIndex()
})

onMounted(() => {})

// find bandwidth
// if (import.meta.client) {
//   console.log(navigator.connection)
// }

const { lgAndUp } = useDisplay()
</script>

<template>
  <ClientOnly>
    <VCol v-if="lgAndUp" cols="12" lg="8" class="d-none d-lg-flex">
      <div class="video-container">
        <video autoplay muted loop id="background-video">
          <source :src="video" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <!-- Your other homepage content goes here -->

        <div v-if="slots.default" class="content-overlay">
          <!-- <h1>TEST</h1> -->
          <slot></slot>
        </div>
      </div>
    </VCol>
  </ClientOnly>
</template>

<style lang="css" scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* reproduce the behavior of background-size: cover but for a video */
#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* #background-video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
} */

.content-overlay {
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  padding: 20px;
}
</style>
